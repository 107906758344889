// import { fontawesome } from '../fontawesome'
// import 'slick-carousel'
// import 'intl-tel-input'
// import 'pwstrength-bootstrap/dist/pwstrength-bootstrap'
// import 'cookieconsent'
import swal from 'sweetalert2'
import WebFont from 'webfontloader'
import Turbolinks from 'turbolinks'
import 'owl.carousel'
// import 'counterup'
// import 'waypoints.min'
import 'jquery-sticky'
import 'magnific-popup'
import 'jquery.mb.ytplayer'
import 'slider-pro'

/**
 * JS Settings App
 */
let jsonSettings = document.querySelector('[data-settings-selector="settings-json"]')
window.settings = jsonSettings ? JSON.parse(jsonSettings.textContent) : {}

window.swal = swal
window.locale = $('html').attr('lang')

export default (createApp) => {
  Turbolinks.start()

  /**
   * Font
   */
  WebFont.load({
    google: {
      families: ['Roboto', 'Lato']
    }
  })

  // Mean Menu
  jQuery('header nav').meanmenu()

  document.addEventListener('turbolinks:load', () => {
    /**
     * Vue Mounting
     */
    if (document.getElementById('app') !== null) {
      const {app} = createApp()
      app.$mount('#app')
    }

    /**
     * Bind all bootstrap tooltips
     */
    $('[data-toggle="tooltip"]').tooltip()

    /**
     * Bind all bootstrap popovers
     */
    $('[data-toggle="popover"]').popover()

    /**
     * Fonts
     */
    // fontawesome.dom.i2svg()

    // Intro Slider
    $('.intro-slider').owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      items: 1,
      loop: true,
      nav: true,
      dots: true,
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',
      responsive: {
        1200: {
          nav: true
        },
        480: {
          nav: false
        }
      }
    })

    // .sri-carousel
    $('.sri-carousel').owlCarousel({
      autoplay: true,
      autoplayHoverPause: true,
      items: 1,
      loop: true,
      nav: false,
      dots: true,
      animateIn: 'fadeIn',
      animateOut: 'fadeOut'
    })

    // Testimonial Slider
    $('.testimonial-slider').owlCarousel({
      items: 2,
      loop: true,
      margin: 30,
      nav: true,
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      responsive: {
        1200: {
          items: 2
        },
        600: {
          items: 1
        },
        0: {
          items: 1
        }
      }
    })

    // Yacht Image Slider
    $('.yacht-image-slider').owlCarousel({
      autoplay: true,
      items: 1,
      loop: true,
      nav: true,
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      responsive: {
        1200: {
          items: 1
        },
        600: {
          items: 1
        },
        0: {
          items: 1
        }
      }
    })

    // Team Slider (About Us Page)
    $('.teams-carousel').owlCarousel({
      items: 4,
      margin: 30,
      nav: true,
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      responsive: {
        1200: {
          items: 4
        },
        600: {
          items: 2,
          nav: false
        },
        0: {
          items: 1,
          nav: false
        }
      }
    })
    $('#example1').sliderPro({
      width: '100%',
      fade: true,
      height: 500,
      arrows: true,
      buttons: false,
      waitForLayers: true,
      thumbnailWidth: 200,
      thumbnailHeight: 100,
      thumbnailPointer: true,
      autoplay: false,
      autoScaleLayers: false,
      breakpoints: {
        500: {
          thumbnailWidth: 120,
          thumbnailHeight: 50
        }
      }
    })
    // Testimonial (About Us Page)
    $('.aut-carousel').owlCarousel({
      items: 1,
      loop: true,
      nav: true,
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      responsive: {
        1200: {
          items: 1
        },
        600: {
          items: 1
        },
        0: {
          items: 1
        }
      }
    })

    // Sticky Menu
    $('.header-main').sticky({ topSpaing: 0 })

    // Video Popup
    $('.video-btn').magnificPopup({
      type: 'iframe',
      iframe: {
        markup: '<div class="mfp-iframe-scaler">' +
              '<div class="mfp-close"></div>' +
              '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
              '</div>',
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: 'http://www.youtube.com/embed/%id%?autoplay=1'
          }
        },
        srcAction: 'iframe_src'
      }
    })

    // Back To Top
    if ($('#back-to-top').length) {
      var scrollTrigger = 100 // px
      var backToTop = function () {
        var scrollTop = $(window).scrollTop()
        if (scrollTop > scrollTrigger) {
          $('#back-to-top').addClass('show')
        } else {
          $('#back-to-top').removeClass('show')
        }
      }
      backToTop()
      $(window).on('scroll', () => {
        backToTop()
      })
      $('#back-to-top').on('click', (e) => {
        e.preventDefault()
        $('html,body').animate({
          scrollTop: 0
        }, 700)
      })
    }

    // Intro Video
    jQuery(() => {
      jQuery('#intro-video').YTPlayer()
    })

    $(window).on('load', (e) => {
      setTimeout(() => {
        $('.spinner').fadeOut('slow')
        setTimeout(() => {
          $('#prelaoder').fadeOut('slow')
          setTimeout(() => {
            $('.content-block').addClass('animated fadeInDown').fadeIn('slow')
            $('#footer').fadeIn('slow')
          }, 900)
        }, 700)
      }, 700)
    })

    /**
     * Bind all swal confirm buttons
     */
    $('[data-toggle="confirm"]').click((e) => {
      e.preventDefault()

      window.swal({
        title: $(e.currentTarget).attr('data-trans-title'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: $(e.currentTarget).attr('data-trans-button-cancel'),
        confirmButtonColor: '#dd4b39',
        confirmButtonText: $(e.currentTarget).attr('data-trans-button-confirm')
      }).then((result) => {
        if (result.value) {
          $(e.target).closest('form').submit()
        }
      })
    })

    // $('[data-toggle="password-strength-meter"]').pwstrength({
    //  ui: {
    //    bootstrap4: true
    //  }
    // })

    // $('[type="tel"]').intlTelInput({
    //  autoPlaceholder: 'aggressive',
    //  utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.14/js/utils.js',
    //  initialCountry: window.locale === 'en' ? 'us' : window.locale,
    //  preferredCountries: ['us', 'gb', 'fr']
    // })

    /**
     * Bootstrap tabs nav specific hash manager
     */
    let hash = document.location.hash
    let tabanchor = $('.nav-tabs a:first')

    if (hash) {
      tabanchor = $(`.nav-tabs a[href="${hash}"]`)
    }

    tabanchor.tab('show')

    $('a[data-toggle="tab"]').on('show.bs.tab', (e) => {
      window.location.hash = e.target.hash
    })
  })
}
